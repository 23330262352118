<template>
  <div id="sheep">
    <transition name="my">
      <template v-if="err" v-cloak>
        <div class="tip" @click="reset">
          <div>槽位已满</div>
        </div>
      </template>
    </transition>
    <div class="k">
      <div class="kn">
        <template v-cloak v-if="data.length>0">
          <div :data-level="item.level" @click="fly(item.no,xh)" class="pai absolute"
               :class="{disable:pd(item.x,item.y,item.level)}"
               v-for="(item,xh) in data"
               :style="'zIndex:'+item.level+';left:'+(item.x/64)+'rem;top:'+(item.y/64)+'rem'" :key="item.xh"><i
              :class="'pai-'+item.no"></i></div>
        </template>
      </div>
    </div>
    <div class="cao">
      <div class="area"></div>
    </div>
    <div class="tools">
      <ul>
        <li>
          <div class="out">随抽三</div>
        </li>
        <li>
          <div class="undo">上一次</div>
        </li>
        <li @click="shuffle">
          <div class="shuffle">洗牌</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import '@/assets/css/lee.css'
import TweenMax from "gsap";

export default {
    name: 'sheep',
    data() {
        return {
            gameConfig: {
                total: 16,//每层生成最大数量
                levelNum: 3//层级
            },//游戏配置
            err: false,//槽位满的时候为true
            data: [],
            area: []//保存在槽内的数组，为了好处理数据
        }
    },
    mounted() {
        let {total, levelNum} = this.gameConfig
        this.createData(total, levelNum)
    },
    watch: {
        area: {
            immediate: true,
            handler(v) {
                //总结：数组长>=7,err=true,除了数组出现三个一样的元素例外，
                if (v.length > 0) {
                    let check = this.tjNum(v)
                    let apperNum = Object.values(check)
                    if (v.length >= 7) {
                        if (apperNum.indexOf(3) > -1) {
                            this.err = false
                        } else {
                            this.err = true
                        }
                    }
                }
            }
        }
    },
    methods: {
        shuffle() {
            let arr = this.data.slice()
            let i = arr.length;
            while (i) {
                let j = Math.floor(Math.random() * i--);
                [arr[j], arr[i]] = [arr[i], arr[j]];
            }
            this.$nextTick(() => {
                this.data = arr
            })
        },
        pd(x, y, level) {
            let fi = this.data.filter((el) => {
                let tjx = Math.abs(el.x - x) === 41
                let tjxx = el.x - x === 0
                let tjy = Math.abs(el.y - y) === 45
                let tjyy = el.y - y === 0
                return (tjx && tjy || (tjxx && tjy) || (tjx && tjyy) || (tjxx && tjyy)) && level < el.level
            })
            if (fi.length > 0) {
                return true
            } else {
                return false
            }
        },
        tjNum(array) {//统计数组中元素出现的次数
            let sum = array.reduce((did, cur) => {
                if (!did[cur]) {
                    did[cur] = 1
                } else {
                    did[cur]++
                }
                return did
            }, {})
            return sum
        },
        reset() {
            this.err = false
            this.area = []
            this.data = []
            document.querySelector('.area').innerHTML = ''
            let {total, levelNum} = this.gameConfig
            this.createData(total, levelNum)
        },
        createData(num, levelNum) {
            let baseRem = document.querySelector('html').style.fontSize.split('px')[0]
            let designRem = 64//设计稿是640PX/10=64
            let designHalf = 82//效果图那个图标宽82px，为了同级别不相交，不然不好判断哪个是不能点击
            let kn = document.querySelector('.kn')
            let w = kn.offsetWidth
            let h = kn.offsetHeight
            let xNum = Math.floor(w / (designHalf * baseRem / designRem))//横向可以放多少个
            let yNum = Math.floor(h / (designHalf * baseRem / designRem))//纵向可以放多少个
            let xPosition = [], yPosition = []//这2个数组是存储元素可以叠放的x,y的位置数组
            for (let i = 0; i < xNum; i++) {
                xPosition.push(i * 82)
            }
            for (let i = 0; i < yNum; i++) {
                yPosition.push(i * 90)
            }
            if (num > xNum * yNum) {
                return
            }
            let zf = [-1, 1]
            for (let i = 0; i < levelNum; i++) {
                let fx = zf[Math.floor(Math.random() * zf.length)]
                let level = i
                let haveNum = Math.ceil(Math.random() * num)
                for (let k = 0; k < haveNum; k++) {
                    this.data.push({
                        no: Math.ceil(Math.random() * 15),//图标有15个
                        level: level,//元素层级
                        x: xPosition[Math.floor(Math.random() * xPosition.length)] + Math.floor(levelNum / 2 - level) * 41 * fx,
                        y: yPosition[Math.floor(Math.random() * yPosition.length)] + Math.floor(levelNum / 2 - level) * 45 * fx
                        //为什么x,y要这样写，为了同级别不相交，不然不好判断哪个是不能点击
                    })
                }
            }
            this.data.sort((a, b) => {
                return a.level - b.level
            })

        },
        fly(no, xh) {
            let kong = document.createElement('div')//这个作用是为了插入空元素，为了缓动后面元素
            let star = document.createElement('div')//插入空的消除星星元素
            let area = document.querySelector('.area')
            let target = event.currentTarget
            let cloneTarget = target.cloneNode(true)
            cloneTarget.classList.remove('absolute')
            cloneTarget.style.cssText = ''
            this.data.splice(xh, 1)
            if (area.children.length > 0) {
                if (this.area.indexOf(no) < 0) {//如果之前没有这个需要添加的元素
                    area.appendChild(cloneTarget)//如果还没有元素，随意追加
                    this.area.push(no)
                } else {
                    let index = this.area.lastIndexOf(no)
                    area.insertBefore(cloneTarget, area.children[index].nextElementSibling);
                    this.area.splice(index, 0, no)//在xh处插入元素值
                    //这个作用是为了插入空的由零变宽的空元素，为了缓动后面元素
                    kong.classList.add('kong');
                    area.insertBefore(kong, area.children[index].nextElementSibling);
                    TweenMax.to(kong, .2, {
                        width: '1.28125rem',
                        onComplete: () => {
                            kong.remove()
                        }
                    })
                }

            } else {
                area.appendChild(cloneTarget)//如果还没有元素，随意追加
                this.area.push(no)
            }

            //动效
            let baseRem = document.querySelector('html').style.fontSize.split('px')[0]
            let designRem = 64//设计稿是640PX/10=64
            let designTop = 34//效果图那个area 的padding-top值
            let kpdleft = 128//效果图那个.k 的padding-left值
            let k = document.querySelector('.k')
            let basey = k.scrollHeight + (designTop * baseRem / designRem)
            let basex = (kpdleft * baseRem / designRem) - (designTop * baseRem / designRem)//k的padding-left减去area 的padding-left值
            let ox = target.offsetLeft + basex
            let oy = target.offsetTop - basey
            let nx = cloneTarget.offsetLeft
            let ny = cloneTarget.offsetTop
            TweenMax.set(cloneTarget, {
                left: ox / baseRem + 'rem',
                top: oy / baseRem + 'rem',
                position: 'absolute',
                zIndex: '44'
            })
            TweenMax.to(cloneTarget, .2, {
                left: nx / baseRem + 'rem', top: ny / baseRem + 'rem', onComplete: () => {
                    cloneTarget.style = ''
                    //如果达到3个，要消除
                    let fi = this.area.filter((el) => {
                        return el === no
                    })
                    if (fi.length === 3) {
                        let dellist = []
                        this.area.map(function (el, index) {
                            if (el === no) {
                                dellist.push(index)
                            }
                        })
                        let k = 0;
                        //假设 dellist=[1,3]保存的是要删除的位置
                        for (let j in dellist) {
                            this.area.splice(dellist[j] - k, 1);
                            area.children[dellist[j] - k].remove()//DOM元素删除
                            k++;
                        }
                        //插入星星动画
                        star.className = 'star have';
                        area.insertBefore(star, area.children[dellist[0]])
                        let st = setTimeout(() => {
                            star.classList.remove('have')
                            TweenMax.to(star, .2, {
                                width: 0,
                                onComplete: () => {
                                    star.remove()
                                    clearTimeout(st)
                                }
                            })
                        }, 1200)
                    }
                }
            });

        }
    },
    computed: {}
}
</script>

